.navbar {
    /* https://www.w3schools.com/howto/howto_css_sticky_element.asp */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-top: 2px;
}

.ul-nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #231e1b;
    display: inline-block;
    /* align-items: center; */
    width: 100%;
}

#negotiumLogo {
    margin-top: 0.8%;
    margin-left: 4px; /* hardcoded in */
    float: left;
    /* margin-top: 50%;
    margin-left: 50%; */
}

#download {
    float: right;
    transform: translateY(25%); 
   /* margin: auto; */ /* hardcoded in */
    /* margin-top: 50%; */
    margin:auto;
    margin-top: 0.24%;
    margin-right: 1%;
}

/* li {
    display: inline-block;
} */