.testimonial {
    background-color: #292929;
    /* min-height: 100px; */
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    justify-content: center;
}

.testimonialInner {
    width: 900px;
}

.testimonialTitle {
    font-size: 40px;
    /* margin-left: -106px; */
    padding-bottom: 20px;
    font-weight: bold;
}

.testimonialLi {
    display: block;
    line-height: 2;
}

.quote {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    /* display: flex;
    justify-content: center; */
}
/* 
.user {
    font-size: large;
    display: flex;
    justify-content: right;
} */