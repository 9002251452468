.team {
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
}

.teamTitle {
    display: flex;
    font-weight: bold;
    font-size: 40px;
    /* justify-content: center; */
    margin-left: 3%;
    /* margin-left: 15%; */
    background-color: #231e1b;
}

.row {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    /* margin-left: 10%; */
}

ul {
    background-color: none;
    /* list-style-type: none; */
    margin: 0;
    padding: 0;
    /* display: inline-block;
    align-items: center;
    width: 100%; */
}

.person {
    text-align: center;
    width: 282px;
}

.name {
    display: block;
    font-weight: bold;
    margin-top: 16px;
    font-size: large;
    background-color: #231e1b;
}

.role {
    display: block;
    background-color: #231e1b;
}

.teamLi {
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
}

/* .photo {
    justify-content: center;
}

.name {
    justify-content: center;
}

.role {
    justify-content: center;
} */