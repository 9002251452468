.footerWrapper {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    justify-content: center;
    background-color:  #292929;
    color: white;

}

#bottomUL {
    display: flex;
    justify-content: center;
}

#topUL {
    display: flex;
    /* justify-content: center;  */
    font-weight: bold;
    font-size: 20px;
    margin-left: 50px;
}

.title {
    font-weight: bold;
    display: block;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 6px;
    margin-bottom: 6px;
    min-width: 90px;
}

.subUL {
    /* list-style-type: none; */
    /* display: inline-block; */
    width: 300px;
    margin-bottom: 10px;
}

a:-webkit-any-link {
    color: white;
    text-decoration: none;
}

.li-footer-outer {
    display: block;
} 

.li-footer {
    display: block;
    margin-left: 50px; 
    margin-right: 50px; 
    margin-top: 6px;
    margin-bottom: 6px;
    min-width: 90px;
} 