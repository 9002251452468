body {
    background-image: url("../assets/backgrounds/background-starry.png");
    background-size: contain;
    background-attachment: fixed;
    background-color: #231e1b;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
}

.main {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
}

.top {
    display: flex;
    justify-content: center;
}

.top_elem {
    display: inline-block;
    align-self: center;
}


.topList {
    font-size: 40px;
    font-weight: bold;
} 

.topListItem {
    text-align: center;
    margin-top: 20px;
    background-color: #231e1b;

    display: block;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 6px;
    line-height: 1.4
}

.middle_elem {
    display: inline-block;
    align-items: center;
}

.middle {
    display: flex;
    align-items: center;
    justify-content: left;
    max-width: 900px;
}

.howItWorksContent {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-bottom: 10px;
    max-width: 900px;
}

.howItWorksPhone {
    height: 420px;
}

#astronautMoonImg {
    background-color: #231e1b;
}

/* #features {
    font-weight: bold;
    font-size: 26px;
} */

/* .featureListItemDiv {
    display: inline-block;
    margin-left: 6%; 
    line-height: 180%;
}

.featureListItemDivPlanet {
    display: inline-block;
}

.featureListDiv {
    display:flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    min-width: 420px;
    margin-top: 4%;
}

#featuresUlList {
    min-width: 600px;
} */

.sectionTitle {
    font-weight: bold;
    font-size: 40px;
    background-color: #231e1b;
}

.subTitle {
    font-weight: bold;
    font-size: 20px;
    background-color: #231e1b;
}

.heavyText {
    font-size: 15px;
    line-height: 150%;
    background-color: #231e1b;
    display: block;
}

.subTitleLarge {
    font-weight: bold;
    font-size: 30px;
    background-color: #231e1b;
    display: block;
}

.phone {
    margin-top: 100px;
}

#overviewPhone {
    margin-top: -160px;
    margin-right: 80px;
}

#overviewPhoneDiv {
    width: 350px;
    display: flex;
    justify-content: right;
}

#mission {
    margin-top: -150px;
    display: block;
    margin-right: 60px;
}

#overviewSubTitle {
    display: block;
    margin-right: 60px;
}

#overviewHeavyText {
    margin-right: 60px;
}

#overviewHeavyText2 {
    margin-right: 60px;
}

#master1 {
    margin-right: 100px;
    margin-left: 10px;
}
 
#master2 {
    margin-right: 100px;
    margin-left: 10px;
}

#master3 {
    margin-left: 100px;
}

#master4 {
    margin-left: 100px;
}

#master5 {
    margin-right: 100px;
    margin-left: 10px;
}

#master6 {
    margin-right: 100px;
    margin-left: 10px;
}